// Variables {{{
var windowSizes = [
	[1200, 0, 'xl'],
	[992, 1200, 'lg'],
	[828, 992, 'md'],
	[575, 828, 'sm'],
	[0, 575, 'xs']
];
var currentWindowSize = 'xl';
var hasScrollTop = false;
var toggledQA = false;
// End Variables }}}
// Get Element {{{
function $(selector) {
	return document.querySelectorAll(selector);
}
// End Get Element }}}
// Remove {{{
Element.prototype.remove = function() {
    this.parentElement.removeChild(this);
}

NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
    for (var i = this.length - 1; i >= 0; i--) {
        if (this[i] && this[i].parentElement) {
            this[i].parentElement.removeChild(this[i]);
        }
    }
}
// End Remove }}}
// On Event {{{
Element.prototype.on = Window.prototype.on = function(e, f) {
	this.addEventListener(e, f);
}

NodeList.prototype.on = HTMLCollection.prototype.on = function(e, f) {
	for (var i = 0; i < this.length; i++) {
		this[i].addEventListener(e, f);
	}
}
// End On Event }}}
// Container size change {{{
function ContainerResized() {
	var w = window.innerWidth;
	for (var i = 0; i < windowSizes.length; i++) {
		if ((windowSizes[i][0] == 0 && w < windowSizes[i][1]) ||
			(w >= windowSizes[i][0] && w < windowSizes[i][1] && currentWindowSize != windowSizes[i][2]) ||
			(w >= windowSizes[i][0] && windowSizes[i][1] == 0 && currentWindowSize != windowSizes[i][2])) {
			currentWindowSize = windowSizes[i][2];
			return true;
		}
	}
}

document.addEventListener('DOMContentLoaded', function() {
	window.on('resize', function() {
		if (ContainerResized()) {
			window.dispatchEvent(new CustomEvent('containerResized'));
		}
	});
});
// End Container size change }}}
// Toggle Slide {{{
function SetSlideToggle (slider) {
	$(slider).on('click', function(e) {
		var heading = this.children[0];
		var container = this.children[1];
		heading.children[0].toggleClass('rotate');
		if (container.style.height == '') {
			heading.style.padding = '20px 30px';
			container.style.height = container.children[0].offsetHeight + 'px';
		} else {
			heading.style.padding = '20px 30px 0 30px';
			container.style.height = '';
		}
	});
	window.on('containerResized', function() {
		[].forEach.call($(slider), function(card) {
			var container = card.children[1];
			var height = container.children[0].offsetHeight;
			if (container.offsetHeight != '0' && container.offsetHeight != height) {
				container.style.height = height + 'px';
			}
		});
	});
}
// End Toggle Slide }}}
// Toggle QA {{{
document.addEventListener("DOMContentLoaded", function() {
	SetSlideToggle('.card-question-anwser');
	[].forEach.call($('.card-question-anwser'), function(card) {
		card.children[1].on('click', function(e) {
			e.stopPropagation();
		});
	});
	$('img.arrow').on('click', function() {
		var section = $('#qa-container')[0].children[0];
		this.toggleClass('flip');
		if (!toggledQA) {
			section.style.bottom = (-section.offsetHeight + 130) + 'px';
			section.style.top = 'auto';
			window.setTimeout(function() {section.style.bottom = '0px';}, 50);
		} else {
			section.style.bottom = (-section.offsetHeight + 130) + 'px';
			window.setTimeout(function() {section.style.bottom = '';}, 300);
		}
		toggledQA = !toggledQA;
	});
});
// End Toggle QA }}}
// Toggle Class {{{
Element.prototype.toggleClass = function(className) {
	if (this.classList.contains(className)) {
		this.classList.remove(className);
	} else {
		this.classList.add(className);
	}
}
// End Toggle Class }}}
// Header {{{
document.addEventListener('DOMContentLoaded', function() {
	$('#site-header button.show-mobile').on('click', function() {
		[].forEach.call($('#site-header #border-container span'), function(border) {
			border.toggleClass('anim');
		});
		$('#site-header nav')[0].toggleClass('expand');
		$('#background-fade')[0].toggleClass('fade');
		$('#mobile-logo img')[0].toggleClass('fade');
	});
});
// End Header }}}
// Scroll-top {{{
document.addEventListener('scroll', function() {
	var scrollTop = document.documentElement.scrollTop;
	if (scrollTop == 0) {
		scrollTop = document.body.scrollTop;
	}
	if ((scrollTop > 400 && !hasScrollTop) || (scrollTop <= 400 && hasScrollTop)) {
		$('#scroll-top')[0].toggleClass('hidden');
		hasScrollTop = !hasScrollTop;
	}
	$('#scroll-top')[0].on('click', function() {
		animateScrollTop(500);
	});
});
function animateScrollTop(scrollDuration) {
    var cosParameter = window.scrollY / 2,
        scrollCount = 0,
        oldTimestamp = performance.now();
    function step (newTimestamp) {
        scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
        if (scrollCount >= Math.PI) window.scrollTo(0, 0);
        if (window.scrollY === 0) return;
        window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
}
// End Scroll-top }}}
// Order Tracking {{{
// document.addEventListener('DOMContentLoaded', function() {
// 	$('a.order-link').on('click', function(e) {
// 		var eStr = location.pathname + '/';
// 		if (location.pathname == '/') {
// 			eStr = '/bästa/';
// 		}
// 		if (this.parentNode.id != '') {
// 			eStr += this.parentNode.id;
// 		} else if (this.parentNode.parentNode.id != '') {
// 			eStr += this.parentNode.parentNode.id;
// 		} else if (this.parentNode.parentNode.parentNode != '') {
// 			eStr += this.parentNode.parentNode.parentNode.id;
// 		}
// 		console.log(eStr);
// 		//ga('send', 'event', 'Order', 'Click', eStr);
// 	});
// });
// End Order Tracking }}}
// Anchor fix {{{
var qs = (function(a) {
    if (a == "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i)
    {
        var p=a[i].split('=', 2);
        if (p.length == 1)
            b[p[0]] = "";
        else
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
})(window.location.search.substr(1).split('&'));

document.addEventListener('DOMContentLoaded', function() {
	if (qs['anchor'] !== undefined) {
		window.scrollTo(0, $('#' + qs['anchor'])[0].getBoundingClientRect().top);
	}
});
// End Anchor fix }}}
// Toggle mobile header menu on bg click {{{
document.addEventListener('DOMContentLoaded', function() {
	$('#background-fade').on('click', function() {
		[].forEach.call($('#site-header #border-container div'), function(border) {
			border.toggleClass('anim');
		});
		$('#site-header nav')[0].toggleClass('expand');
		$('#background-fade')[0].toggleClass('fade');
		$('#mobile-logo img')[0].toggleClass('fade');
	});
});
// }}}
